export const useRouteParameter = (
  key: string,
  index: number = 0,
): ComputedRef<string> => {
  const route = useRoute()

  return computed<string>(() => {
    const parameter = route.params[key]

    return Array.isArray(parameter) ? parameter[index] : parameter
  })
}
